function searchColleagues(str) {
    if (!window.colleagues) {
        window.colleagues = JSON.parse(window.localStorage.getItem('colleagues'));
    }
    if (!str.length) return [];
    str = str.toLowerCase();
    const found = new Set(window.colleagues.filter(c => c.code.toLowerCase().startsWith(str)));
    window.colleagues.forEach(c => {
        if (c.firstName.toLowerCase().startsWith(str) || c.lastName.toLowerCase().startsWith(str) || `${c.firstName} ${c.lastName}`.toLowerCase().startsWith(str)) {
            found.add(c);
        }
    });
    return [...found].map(c => ({ text: `${c.firstName} ${c.lastName} (${c.code})`, value: c.code.toUpperCase() }));
}

function searchString(haystack) {
    return (needle) => {
        if (!needle.length) return [];
        needle = needle.toLowerCase();
        return haystack.filter(str => str?.toLowerCase().includes(needle)).map(i => ({ text: i, value: i }));
    }
}

export { searchColleagues, searchString };
