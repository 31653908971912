<template>
  <div class="autosuggest">
    <input type="text" :value="content" :placeholder="placeholder" @input="handleInput" @blur="blur">
    <ul v-if="suggestions && suggestions.length">
      <li
          v-for="(item, index) in suggestions" :key="item.value"
          v-text="item.text"
          :class="{ selected: selected === index }"
          @click="useSuggestion(index)"
          @mouseenter="selected = index"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AutoSuggest.vue",
  data: () => ({
    content: '',
    suggestions: [],
    selected: 0,
  }),
  props: {
    value: String,
    placeholder: String,
    searchFunction: Function,
    delimiter: String,
    alwaysAdd: String,
    upperCase: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    value(v) {
      this.content = v;
    },
  },
  methods: {
    handleInput(event) {
      const v = event.target.value;
      const parts = v.split(this.delimiter).map(p => p.trim());
      this.suggestions = this.searchFunction(parts[parts.length - 1]);
      this.selected = 0;
      this.$emit('input', v);
    },
    useSuggestion(index) {
      const parts = this.content.split(this.delimiter).map(p => p.trim());
      parts[parts.length - 1] = this.suggestions[index]?.value;
      this.content = parts.join(`${this.delimiter} `);
      this.$emit('input', this.content);
      this.suggestions = [];
    },
    blur() {
      setTimeout(() => {
        if (this.upperCase && this.content) {
          this.content = this.content.toUpperCase();
        }
        if (this.alwaysAdd && this.content) {
          this.content =
              [...((new Set(this.content.split(this.delimiter).map(p => p.trim()))).add(this.alwaysAdd))]
              .sort((a, b) => a.localeCompare(b, 'de'))
              .join(`${this.delimiter} `)
          ;
        }
        this.$emit('input', this.content);
        this.$emit('blur');
        this.suggestions = [];
      },300);
    },
  },
  created() {
    this.content = this.value;
  },
  mounted() {
    window.addEventListener('keydown', e => {
      if (this.suggestions.length) {
        if (e.key === 'ArrowDown') {
          this.selected = (this.selected + 1) % this.suggestions.length;
        }
        if (e.key === 'ArrowUp') {
          this.selected = (this.selected + this.suggestions.length - 1) % this.suggestions.length;
        }
        if (e.key === 'Enter') {
          this.useSuggestion(this.selected);
        }
      }
    });
  },
}
</script>

<style lang="scss">
.autosuggest {
  position: relative;

  ul {
    position: absolute;
    background-color: white;
    border: 1px solid darkgrey;
    border-radius: 3px;
    z-index: 3;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      white-space: nowrap;
      line-height: 1.5;
      padding: .1rem .5rem;
      cursor: pointer;

      &.selected {
        background-color: #666;
        color: white;
      }

      &:first-child {
        padding-top: .5rem;
      }
      &:last-child {
        padding-bottom: .5rem;
      }
    }
  }
}
</style>
