<template>
  <div :class="['row', {  billed: entry.billed, 'not-billed': !entry.billed && entry.comspaceTicket , gap}]">
    <div class="cell checkbox">
      <input
          type="checkbox"
          v-model="entry.billed"
          @change="emitChange"
      />
    </div>
    <div class="cell ticket">
      <auto-suggest
          v-model="entry.comspaceTicket"
          placeholder="Ticket"
          :upper-case="true"
          :search-function="autosuggest(Array.from(history.comspaceTickets))"
          @blur="emitChange"
      ></auto-suggest>
    </div>
    <div class="cell ticket">
      <auto-suggest
          v-model="entry.external"
          placeholder="Extern"
          :upper-case="true"
          :search-function="autosuggest(Array.from(history.externalTickets.keys()))"
          @input="findMatchingComspaceTicket"
          @blur="emitChange"
      ></auto-suggest>
    </div>
    <div class="cell time">
      <input type="time" step="900" v-model="entry.start" placeholder="Start"
             @blur="emitChange"
      >
    </div>
    <div class="cell time">
      <input type="time" step="900" v-model="entry.end" placeholder="Ende"
             @blur="emitChange"
      >
    </div>
    <div class="cell time">
      <input readonly type="time" step="900" :value="duration" placeholder="Dauer">
    </div>
    <div class="cell text">
      <auto-suggest
          v-model="entry.bookingText"
          placeholder="Text"
          :search-function="autosuggest(Array.from(history.bookingTexts))"
          @blur="emitChange"
      ></auto-suggest>
    </div>
    <div class="cell note">
      <input type="text" v-model="entry.note" placeholder="Hinweis"
             @blur="emitChange"
      >
    </div>
    <div class="cell participants">
      <auto-suggest
          v-model="entry.participants"
          placeholder="Teilnehmende"
          :upper-case="true"
          :search-function="searchColleagues()"
          :always-add="user"
          delimiter=","
          @blur="emitChange"
      ></auto-suggest>
    </div>
    <div class="cell buttons">
      <button type="button" @click="markdown" title="Einen Markdown-Link erzeugen (für Kollegen)">Ⓜ️</button>
      <button type="button" @click="account" title="Den Buchungslink kopieren">🔗</button>
      <button type="button" @click="removeEntry" title="Den Eintrag entfernen">➖</button>
      <button type="button" @click="addEntry" title="Einen weiteren Eintrag hinzufügen">➕</button>
    </div>
  </div>
</template>

<script>
import { timeStringToMinutes, minutesToTimeString } from '../TimeUtils';
import AutoSuggest from "./AutoSuggest.vue";
import { searchColleagues, searchString } from "../AutoSuggestion";

export default {
  name: "BookingPosition.vue",
  data: () => ({
    entry: null,
  }),
  props: {
    initial: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    last: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Object,
    },
    user: String,
    gap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    duration() {
      if (!this.entry.start || !this.entry.end) {
        return null;
      }
      const duration = timeStringToMinutes(this.entry.end) - timeStringToMinutes(this.entry.start);
      return minutesToTimeString(duration);
    },
  },
  watch: {
    initial: {
      deep: true,
      handler() {
        this.entry = {...this.initial};
      },
    }
  },
  methods: {
    addEntry() {
      this.$emit('addEntry');
    },
    removeEntry() {
      this.$emit('removeEntry');
    },
    account() {
      this.$emit('account', this.entry);
    },
    markdown() {
      this.$emit('markdown', this.entry);
    },
    emitChange() {
      this.$emit('change', this.entry);
    },
    searchColleagues() {
      return searchColleagues;
    },
    autosuggest(list) {
      return searchString(list);
    },
    findMatchingComspaceTicket() {
      const found = this.history.externalTickets.get(this.entry.external);
      if (this.entry.external && !this.entry.comspaceTicket && found) {
        this.entry.comspaceTicket = found;
      }
    }
  },
  components: {
    AutoSuggest,
  },
  created() {
    this.entry = {...this.initial};
  }
}
</script>

<style lang="scss">
.row {

  &.not-billed input {
    border-bottom-color: #e18309;
  }
  &.billed input {
    border-bottom-color: #9bbd3d;
  }

  &.gap {
    .cell {
      border-top-width: 2rem;
      border-top-color: transparent;
      border-top-style: solid;
    }
  }

  button {
    width: 2rem;
    margin-right: .5rem;
  }
}
input {
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #bbb;
  line-height: 1.25rem;

  &[type=text] {
    width: 100%;
  }
}
</style>
