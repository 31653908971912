<template>
  <dialog open v-if="noColleagues" >
    <h1>Erste Schritte</h1>
    <p>Für die Autovervollständigung der Teilnehmenden wird eine Liste der comspace-Mitarbeiter im JSON-Format benötigt.</p>
    <pre><code data-language="json5"> [
     <span class="cm-comment">//...</span>
     {
         <span class="cm-property">"code"</span>: <span class="cm-string">"sro"</span>,
         <span class="cm-property">"firstName"</span>: <span class="cm-string">"Stefan"</span>,
         <span class="cm-property">"lastName"</span>: <span class="cm-string">"Rolfs"</span>
     },
     <span class="cm-comment">// ...</span>
 ]
</code></pre>
    <p>Um eine solche Liste zu generieren kann der folgende Code verwendet werden. Kopiere den Code in die
      Entwickler-Konsole während <a href="https://jira.comspace.de/" target="_blank">Jira</a> geöffnet ist.</p>
    <pre><code data-language="javascript"><span class="cm-variable">JSON</span>.<span class="cm-property">stringify</span>(
    (<span class="cm-keyword">await</span> <span class="cm-variable">fetch</span>(<span class="cm-string">'https://jira.comspace.de/rest/api/2/user/search?username=.&amp;maxResults=1000'</span>).<span class="cm-property">then</span>((<span class="cm-def">res</span>) <span class="cm-operator">=&gt;</span> <span class="cm-variable-2">res</span>.<span class="cm-property">json</span>()))
        .<span class="cm-property">map</span>(<span class="cm-def">u</span> <span class="cm-operator">=&gt;</span> {
            <span class="cm-keyword">const</span> <span class="cm-def">displayName</span> <span class="cm-operator">=</span> <span class="cm-variable-2">u</span>.<span class="cm-property">displayName</span>.<span class="cm-property">split</span>(<span class="cm-string">' '</span>);
            <span class="cm-keyword">return</span> { <span class="cm-property">code</span>: <span class="cm-variable-2">u</span>.<span class="cm-property">name</span>, <span class="cm-property">firstName</span>: <span class="cm-variable-2">displayName</span>[<span class="cm-number">0</span>], <span class="cm-property">lastName</span>: <span class="cm-variable-2">displayName</span>[<span class="cm-number">1</span>] }
        })
        .<span class="cm-property">filter</span>(<span class="cm-def">u</span> <span class="cm-operator">=&gt;</span> <span class="cm-variable-2">u</span>.<span class="cm-property">code</span>.<span class="cm-property">length</span> <span class="cm-operator">&lt;</span> <span class="cm-number">5</span> <span class="cm-operator">&amp;&amp;</span> <span class="cm-variable-2">u</span>.<span class="cm-property">lastName</span>)
)
</code></pre>
    <p>Nach Absetzen des Befehls kopierst du die resultierende JSON-Liste in dieses Textfeld.</p>
    <form method="dialog">
      <textarea rows="6" v-model="colleagues"></textarea>
      <p>Alle eingegebenen Daten werden ausschließlich im Local Storage deines Browsers abgelegt.</p>
      <div class="btn"><button @click="saveColleagues">Alles klar!</button></div>
    </form>
  </dialog>
</template>

<script>
export default {
  name: "FirstSteps.vue",
  data: () => ({
    colleagues: '[{"code":"sro","firstName":"Stefan","lastName":"Rolfs"}]',
    noColleagues: [],
  }),
  methods: {
    checkColleagues() {
      const colleagues = window.localStorage.getItem('colleagues');
      if (!!colleagues && colleagues !== 'null') {
        this.colleagues = colleagues;
      }
      const arr = JSON.parse(this.colleagues);
      this.noColleagues = !arr || !arr.length || arr.length < 2;
    },
    saveColleagues() {
      window.localStorage.setItem('colleagues', this.colleagues);
      this.checkColleagues();
    },
  },
  created() {
    this.checkColleagues();
  }
}
</script>

<style scoped>
dialog {
  z-index: 999;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
dialog::backdrop {
  background: rgba(255, 0, 0, 0.25);
}
a {
  color: #7994ba;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
textarea {
  display: block;
  width: 100%;
  margin-bottom: 1em;
}
pre code {
  display: block;
  font-size: 12px;
  line-height: 18px;
  white-space: pre-wrap;
  background-clip: padding-box;
  border-radius: 3px;
  border: 1px solid #DFE1E6;
  background-color: #F4F5F7;
  margin: 0 2px -1px;
  padding: 0 3px;
  text-decoration: inherit;
  vertical-align: baseline;
}
.cm-comment {
  color: #696969;
}
.cm-property, .cm-def {
  color: #336ea9;
}
.cm-string {
  color: #c03e32;
}
.cm-keyword {
  color: #815b3a;
}
.cm-variable {
  color: #4a6785;
}
.cm-number {
  color: #127b27;
}
form .btn {
  text-align: right;
}
</style>