<template>
  <div class="history">
    <first-steps></first-steps>
    <div class="day" v-for="day in limitedDays" :key="day.date">
      <h2><span v-text="day.date"></span> <span class="sum" v-if="day.sum" v-text="`${day.sum}h`"></span></h2>
      <div class="booking-entries">
        <div class="row head">
          <div class="cell"></div>
          <div class="cell">Ticket</div>
          <div class="cell">Extern</div>
          <div class="cell">Start</div>
          <div class="cell">Ende</div>
          <div class="cell">Dauer</div>
          <div class="cell">Text</div>
          <div class="cell"><span title="das siehst nur du">Hinweis</span></div>
          <div class="cell"><span title="optional">Teilnehmende</span></div>
          <div class="cell"></div>
        </div>
        <booking-position
            v-for="(entry, index) in day.entries"
            :key="`${index}${day.start}`"
            :initial="entry"
            :gap="index > 0 && day.entries[index - 1].end !== entry.start"
            :date="day.date"
            :last="index === day.entries.length - 1"
            :history="history"
            :user="user"
            @change="updateHistory($event, day, index)"
            @addEntry="addEntry(day, index)"
            @removeEntry="removeEntry(day, index)"
            @account="account(day, $event)"
            @markdown="markdown(day, $event)"
        >
        </booking-position>
      </div>
    </div>
    <button class="more" v-if="limit < this.days.length" @click="showMoreDays">⬇ ⬇ ⬇</button>
  </div>
</template>

<script>
import BookingPosition from "./components/BookingPosition.vue";
import FirstSteps from "./components/FirstSteps.vue";
import { timeStringToMinutes, minutesToTimeString } from './TimeUtils';

export default {
  name: 'App',
  data: () => ({
    days: null,
    user: null,
    history: {
      comspaceTickets: new Set(),
      externalTickets: new Map(),
      bookingTexts: new Set(),
    },
    limit: 7,
  }),
  computed: {
    limitedDays() {
      return this.days.slice(0, this.limit);
    },
  },
  methods: {
    showMoreDays() {
      this.limit += 7;
    },
    loadUser() {
      this.user = window.localStorage.getItem('user');
      if (!this.user || this.user === 'null') {
        this.user = window.prompt('Wie lautet dein Kürzel?', 'SRO');
        window.localStorage.setItem('user', this.user);
      }
    },
    loadHistory() {
      const today = {
        date: new Date().toJSON().slice(0, 10),
        entries: [this.createNewBookingEntry()],
        sum: '0:00',
      };

      let history = window.localStorage.getItem('bookingHistory');
      if (history) {
        history = JSON.parse(history);
        history.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        history.forEach((day) => {
          day.entries.sort((a, b) => {
            return timeStringToMinutes(a.start) - timeStringToMinutes(b.start);
          });
        });
      } else {
        history = [today];
      }
      this.days = history;

      this.categorizeHistory();
      this.fillUpHistory(today);
    },
    fillUpHistory(today) {
      while (this.days[0].date !== today.date) {
        let nextDate = new Date(this.days[0].date);
        nextDate.setHours(10);
        nextDate.setDate(nextDate.getDate() + 1);
        this.days.unshift({
          date: nextDate.toJSON().slice(0, 10),
          entries: [this.createNewBookingEntry()],
          sum: '0:00',
        });
      }
    },
    categorizeHistory() {
      this.days.forEach(day => {
        day.entries.forEach(entry => {
          this.history.comspaceTickets.add(entry.comspaceTicket);
          this.history.bookingTexts.add(entry.bookingText);
          if (entry.external) {
            this.history.externalTickets.set(entry.external, entry.comspaceTicket);
          }
        })
      })
    },
    saveHistory() {
      window.localStorage.setItem('bookingHistory', JSON.stringify(this.days));
    },
    addEntry(day, index) {
      const lastEnd = day.entries[index].end;
      day.entries.splice(index + 1, 0, this.createNewBookingEntry(lastEnd));
    },
    isEmpty(entry) {
      return !entry.comspaceTicket && !entry.externalTicket && !entry.bookingText && !entry.note && !entry.participants;
    },
    removeEntry(day, index) {
      if (this.isEmpty(day.entries[index])
          || window.confirm(`Den Eintrag „${this.getBookingText(day.entries[index])}“ löschen?`)) {
        day.entries.splice(index, 1);
        if (!day.entries.length) {
          day.entries.push(this.createNewBookingEntry());
        }
        this.saveHistory();
      }
    },
    getUrlForAccounting(day, entry, minutes) {
      const params = new URLSearchParams();
      params.append('cap', 'cap');
      if (entry.comspaceTicket) params.append('issueKey', entry.comspaceTicket);
      if (entry.external) params.append('external', entry.external);
      if (entry.bookingText) params.append('TaskDetail', this.getBookingText(entry));
      if (minutes) params.append('time', minutes / 60);
      if (day.date) params.append('Datum', (new Date(day.date).toLocaleDateString('de-DE', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })));
      return `https://jira.comspace.de/secure/IctCreateWorklog!default.jspa?${params.toString().replace(/\+/g, '%20')}`;
    },
    markdown(day, entry) {
      const url = this.getUrlForAccounting(day, entry, this.sumUpMinutesForDay([entry]));
      const markdown = `[${this.getBookingText(entry)}](${url})`;
      navigator.clipboard.writeText(markdown);
    },
    account(day, entry) {
      const entriesToAccounted =
        day.entries.filter(e => (
          e.comspaceTicket === entry.comspaceTicket
          && e.external === entry.external
          && e.bookingText === entry.bookingText
          && e.participants === entry.participants
          && e.start !== null
          && e.end !== null
        ));

      const minutes = this.sumUpMinutesForDay(entriesToAccounted);
      const url = this.getUrlForAccounting(day, entry, minutes);
      navigator.clipboard.writeText(url);

      entriesToAccounted.forEach(e => {
        e.billed = true;
      })
    },
    sumUpMinutesForDay(entries) {
      return entries
        .map(e => timeStringToMinutes(e.end) - timeStringToMinutes(e.start))
        .reduce((partial_sum, a) => partial_sum + a, 0);
    },
    getBookingText(entry) {
      let bookingText = entry.bookingText;
      const issueRegEx = /^[A-Z]{2,}-\d+$/;
      if (entry.external && issueRegEx.test(entry.external)) {
        bookingText = entry.external + ': ' + bookingText;
      }
      if (entry?.participants?.trim()) {
        bookingText = bookingText + ' // ' + entry.participants;
      }
      return bookingText;
    },
    updateHistory(data, day, index) {
      day.entries[index] = data;
      day.sum = minutesToTimeString(this.sumUpMinutesForDay(day.entries.filter(e => e.comspaceTicket)));
      this.saveHistory();
    },
    createNewBookingEntry(lastEnd) {
      const today = new Date();
      if (lastEnd) {
        const parts = lastEnd.split(':');
        today.setHours(parseInt(parts[0]));
        today.setMinutes(parseInt(parts[1]));
      }

      return {
        billed: false,
        comspaceTicket: '',
        start: this.getPreviousQuarter(today),
        end: this.getNextQuarter(today),
        externalTicket: '',
        bookingText: '',
        note: '',
        participants: '',
      };
    },
    getPreviousQuarter(date) {
      return `${date.getHours().toString().padStart(2, '0')}:${(date.getMinutes() - (date.getMinutes() % 15)).toString().padStart(2, '0')}`;
    },
    getNextQuarter(date) {
      const later = new Date(date.getTime() + 15 * 60000);
      return `${later.getHours().toString().padStart(2, '0')}:${(later.getMinutes() - (later.getMinutes() % 15)).toString().padStart(2, '0')}`;
    },
  },
  components: {
    FirstSteps,
    BookingPosition,
  },
  created() {
    this.loadHistory();
    this.loadUser();
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
body {
  font-family: sans-serif;
  padding: 0;
  margin: 0;
}
.day {
  border-top: 2px double #ebecf0;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  &:first-child {
    border-top: none;
  }

  &:nth-child(even) {
    background-color: #F4F5F7;
    //background-color: #fafbfc;
  }

  h2 {
    margin-top: 0;
    padding-left: 20px;
    color: #172b4d;
    font-weight: 500;
    letter-spacing: -.01em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;

    .sum {
      font-family: monospace;
    }
  }

  .booking-entries {
    display: table;
    width: 100%;

    .row {
      display: table-row;

      &.head .cell {
        padding: 10px;
        border-bottom: 1px solid #c1c7d0;
        color: #5e6c84;
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;
      }

      &:nth-child(2) .cell {
        padding-top: 10px;
      }
    }

    .cell {
      display: table-cell;
      padding: 3px 0 3px 10px;

      &:first-child {
        padding-left: 20px;
      }

      &.checkbox {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        padding-top: 2px;
        padding-bottom: 2px;
        margin: 0;
      }

      &.ticket {
        width: 120px;
        min-width: 120px;
        max-width: 120px;
      }

      &.time {
        width: 90px;
        min-width: 90px;
        max-width: 90px;
      }

      &.text {
        min-width: 300px;
      }

      &.note {
        width: 300px;
      }

      &.participants {
        width: 120px;
      }

      &.buttons {
        width: 175px;
        min-width: 175px;
        max-width: 175px;
      }
    }
  }
}
button.more {
  letter-spacing: 10px;
  padding: 10px 40px;
  margin: 40px auto;
  display: block;
}
</style>
