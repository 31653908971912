function timeStringToMinutes(timeString) {
    const parts = timeString.split(':').map(i => parseInt(i, 10));
    return parts[0] * 60 + parts[1];
}

function minutesToTimeString(minutes) {
    return `${Math.floor(minutes / 60).toString().padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}`;
}

export {timeStringToMinutes, minutesToTimeString};
